<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col hidden lg:block lg:w-1/2 xl:block xl:w-1/2">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/2 d-theme-dark-bg">
              <div class="px-8 pt-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>
                <div class="login-firebase-container">
                  <vs-input
                    v-validate="'required|email|min:3'"
                    data-vv-validate-on="blur"
                    id="username"
                    name="username"
                    label="Username"
                    v-model="form.username"
                    class="w-full mt-5"/>
                  <span class="text-danger text-sm">{{ errors.first('username') }}</span>

                  <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required|min:6|max:10'"
                    type="password"
                    id="password"
                    name="password"
                    label="Password"
                    v-model="form.password"
                    class="w-full mt-5" />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-between my-5">
<!--                    <vs-checkbox v-model="checkbox_remember_me" class="mb-3" id="checkbox_remember_me">Remember Me</vs-checkbox>-->
<!--                    <router-link to="/pages/forgot-password">Forgot Password?</router-link>-->
                  </div>
                  <vs-button class="float-left" @click="registerUser" id="registerUser">Register</vs-button>
                  <vs-button class="float-right" :disabled="!validateForm" @click="login" id="loginFirebase">Login</vs-button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>


<script>
import LoginJwt from './LoginJWT.vue'
import LoginFirebase from './LoginFirebase.vue'
import LoginAuth0 from './LoginAuth0.vue'
import {getToken} from "@/utils/storage";

export default {
  components: {
    LoginJwt,
    LoginFirebase,
    LoginAuth0
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      checkbox_remember_me: false
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.email !== '' && this.password !== ''
    },
    currentUser() {
      return this.$store.state.AppActiveUser;
    },
  },
  watch: {
    currentUser (newCount, oldCount) {
      // Our fancy notification (2).
      console.log(newCount, oldCount )
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    login () {
      // Loading
      this.$vs.loading()
      this.$store.dispatch('auth/loginUser', this.form).then(() => {
        this.$vs.loading.close()
        this.$store.dispatch('auth/getUserProfile').then(() => {
          window.location.href = '/'
        })

      })
      // const payload = {
      //   checkbox_remember_me: this.checkbox_remember_me,
      //   userDetails: {
      //     email: this.email,
      //     password: this.password
      //   },
      //   notify: this.$vs.notify,
      //   closeAnimation: this.$vs.loading.close,
      // }
      // this.$store.dispatch('auth/loginAttempt', payload);
    },

    registerUser () {
      if (!this.checkLogin()) return
      this.$router.push('/pages/register').catch(() => {})
    },
  },
  mounted() {
    if (getToken()) {
      window.location.href = '/'
    }
  }
}
</script>

<style lang="scss">
.login-tabs-container {
  min-height: 505px;

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}
</style>
